export default [
  {
    text: `Print the following patterns using nested loops
~~~
1
11
111
1111
11111
~~~
    
    `,
    type: "code",
    lang: "java",
  },
  {
    text: `
~~~
1
12
123
1234
12345
~~~
    `,
    type: "code",
    lang: "java",
  },
  {
    text: `
~~~
1
21
321
4321
54321
~~~
    `,
    type: "code",
    lang: "java",
  },
  {
    text: `
~~~
*
**
***
****
*****
~~~
    `,
    type: "code",
    lang: "java",
  },
  {
    text: `
~~~
1
**
333
****
55555
~~~
    `,
    type: "code",
    lang: "java",
  },
  {
    text: `
~~~
5
45
345
2345
12345
~~~
    `,
    type: "code",
    lang: "java",
  },
  {
    text: `
~~~
-1
--2
---3
----4
-----5
~~~
    `,
    type: "code",
    lang: "java",
  },
  {
    text: `
~~~
*
*#*
*#*#*
*#*#*#*
*#*#*#*#*
~~~
    `,
    type: "code",
    lang: "java",
  },
  {
    text: `
~~~
    *
   *#*
  *#*#*
 *#*#*#*
*#*#*#*#*
~~~
    `,
    type: "code",
    lang: "java",
  },

  {
    text: `
~~~
    1
   121
  12321
 1234321
123454321
~~~
    `,
    type: "code",
    lang: "java",
  },
];
