const obj = [
  {
    text: `What is the correct statement about the following code?

\`\`\`java    
ArrayList<Integer> l = new ArrayList<>();
l.add(1);
l.add(2);

for(int v : l) {
  v++;
}
\`\`\`
    `,
    type: "select",
    selectOptions:
      "arraylist's values are all incremented by one,arraylist's values are unchanged,syntax error,runtime error",
  },
  {
    text: `What is the correct statement about the following code?

\`\`\`java    
ArrayList<Integer> l = new ArrayList<>();
l.add(1);
l.add(2);

for(int i = 0; i < l.size(); i++) {
  l.get(i)++;
}
\`\`\`
    `,
    type: "select",
    selectOptions:
      "arraylist's values are all incremented by one,arraylist's values are unchanged,syntax error,runtime error",
  },
  {
    text: `Write a code that decrements every value from an arraylist \`l\``,
    type: "code",
    lang: "java",
    placeholder: `ArrayList<Integer> l = new ArrayList<>();\n\n//implement`,
  },
  {
    text: `Determine the outcome of the following code 

~~~java
class Main {
  public static void main(String args[]) {
    int x = 10;
    int y = x;
    y++;

    System.out.println(x);

    int[] a = { 1, 2, 3 };
    int[] b = a;
    a[0]++;

    System.out.println(b);
  }
}
~~~ 
    `,
    type: "select",
    selectOptions: "10 and 1,10 and 2,11 and 1,11 and 2,None of the above",
  },
  {
    text: `Determine the arraylist content after the following code

~~~java
ArrayList<Integer> l = new ArrayList<>();
l.add(2);
l.add(2);
l.add(2);

for(int i=0; i<l.size(); i++) {
  if (l.get(i) == 2) {
    l.remove(i);
  }
}
~~~
    `,
    type: "select",
    selectOptions:
      "All three 2s are removed,Only two 2s are remove,Only one 2 is removed,Nothong is removed,Error",
  },
  {
    text: `How many 2s will remain in the arraylist after the following code?

~~~java
ArrayList<Integer> l = new ArrayList<>();
l.add(2);
l.add(2);
l.add(2);
l.add(2);

for(int i=size() - 1; i >= 0; i--) {
  if (l.get(i) == 2) {
    l.remove(i);
  }
}
~~~
    `,
    type: "select",
    selectOptions: "4,3,2,1,0,Error",
  },
  {
    text: `Determine the outcome of the following code
    
~~~java
ArrayList<Integer> l = new ArrayList<>();
l.add(1);
l.add(2);

for(int i=0; i<l.size(); i++) {
  if (l.get(i) % 2 == 0)
    l.add(i, 4);
}

System.out.println(l);
~~~
    `,
    type: "select",
    selectOptions: ["[1,2]", "[1,2,4]", "[1,4,2]", "Infinite Loop", "Error"],
  },
  {
    text: `Determine the outcome of the following code
    
~~~java
ArrayList<Integer> l = new ArrayList<>();
l.add(2);
l.add(4);

for(int i=0; i<l.size(); i++) {
  if (l.get(i) % 2 == 0)
    l.add(i, l.get(i) + 1);
}

System.out.println(l);
~~~
    `,
    type: "select",
    selectOptions: [
      "[2,3,4,5]",
      "[3,2,5,4]",
      "[2,4,3,5]",
      "Infinite Loop",
      "Error",
    ],
  },
  {
    text: `Write a code that counts the occurrences of "LL" in a string. For example, "LL" appears 3 times in a string "LLL".`,
    type: "code",
    lang: "java",
  },
];

export default obj;
