export default [
  {
    text: "Grade",
    type: "select",
    selectOptions: "3,4,5,6,7,8,freshman,sophomore,junior,senior,college,adult",
  },
  {
    text: `Given X, print "Hi" if X is greater than 10 and print "Bye" if otherwise.`,
    type: "code",
    lang: "python",
    placeholder: `
X = 15
`,
  },
  {
    text: `Given X, print "Hi" if X is greater than 10.`,
    type: "code",
    lang: "python",
    placeholder: `
X = 15
`,
  },
  {
    text: `Given N, print "EVEN" if N is even.`,
    type: "code",
    lang: "python",
    placeholder: `
N = 10
`,
  },
  {
    text: `Given Z, print "A" if the number is equal to 5 or print "B" if the number is equal to 3.`,
    type: "code",
    lang: "python",
    placeholder: `
Z = 5
`,
  },
  {
    text: `Given num, print "EVEN" if num is even or "ODD" if num is odd.`,
    type: "code",
    lang: "python",
    placeholder: `
num = 8
`,
  },
  {
    text: `Given num, print 1 if num is divisible by 3 or print 2 if num is divisble by 5.`,
    type: "code",
    lang: "python",
    placeholder: `
num = 3
`,
  },
  {
    text: `Given num, print 1 if num is divisible by 3 or print 2 if num is divisble by 5.`,
    type: "code",
    lang: "python",
    placeholder: `
num = 3
`,
  },
  {
    text: `Given num, print 1 if num is divisible by 3 or print 2 if num is divisble by 5 or print 3 if num is divisible by 3 and 5.`,
    type: "code",
    lang: "python",
    placeholder: `
num = 15
`,
  },
];
