export default [

  {
    text: `What is the value of x after the following code is executed?

\`\`\`java
int x = 10;
int y = 20;
y = x;
\`\`\`    

    
`,
    type: "text",
  },
  {
    text: [
      "What is the printed result of the following code?",
      `
\`\`\`java
int x = 20;

if (10 <= x <= 20) {
  System.out.println("yes");
} else {
  System.out.println("no");
}
\`\`\`
  `,
    ],
    type: "select",
    selectOptions: "yes,no,error",
  },
  {
    text: [
      "What is the printed result of the following code?",
      `
\`\`\`java
int x = scanner.nextInt();  // user inputs a number

if (x != 0 && 5 / x > 0)
  System.out.println("yes");
else
  System.out.println("no");

\`\`\`
  `,
    ],
    type: "select",
    selectOptions: "yes,no,error",
  },
  {
    text: `Write a java program that computes the sum of all 2-digit even numbers, excluding the multiples of 8s.`,
    type: "code",
    lang: "java",
  },
  {
    text: `Write a java program that prints the ***smallest*** number that is greater than 100 and is divisible by 13`,
    type: "code",
    lang: "java",
  },
  {
    text: "Write a java program that prints \"prime\" if the variable `x` is a prime number.",
    type: "code",
    lang: "java",
  },
];
