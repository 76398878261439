import QPython from "./data/python.json";
import QJava from "./data/java.json";
import QApCSA from "./data/apcsa";
import QLogic from "./data/logic";
import QConditionals from "./data/conditionals";
import QAPCSA_Basic from "./data/apcsa-basic";
import QAPCSA_Object from "./data/apcsa-object";
import QAPCSA_Nested from "./data/apcsa-nested";
import QAPCSA_Nested2 from "./data/apcsa-nested-2";
import QAPCSA_OOP1 from "./data/apcsa-oop1";
import QAPCSA_OOP2 from "./data/apcsa-oop2";
import QAPCSA_OOP3 from "./data/apcsa-oop3";
import QTRACING_1 from "./data/tracing-1";
import QAPCSA_ref from "./data/apcsa-ref";
import QAPCSA_MOCK1 from "./data/apcsa-mock-1";
import QRandom from "./data/random";
import QAPCSA_Inheritance1 from "./data/apcsa-inheritance1";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-python";
import "prismjs/themes/prism.css";
import ReactMarkdown from "react-markdown";
import React, { useEffect, useMemo, useState } from "react";
import { BrowserRouter, Route, Switch, useParams } from "react-router-dom";
import { fstore } from "./utils/fb";
import { formatDistanceToNow, subDays } from "date-fns";
import rehypeRaw from "rehype-raw";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneLight as textCodeTheme } from "react-syntax-highlighter/dist/cjs/styles/hljs";

import "./styles.css";

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/view-entries" component={Viewer} />
        <Route path="/lang/:lang" component={Survey} />
        <Route path="/" component={Survey} />
      </Switch>
    </BrowserRouter>
  );
}

const types = [
  "java",
  "python",
  "apcsa",
  "apcsa-basic",
  "apcsa-object",
  "apcsa-nested",
  "apcsa-nested-2",
  "apcsa-inheritance-1",
  "apcsa-ref",
  "logic",
  "conditionals",
  "apcsa-oop1",
  "apcsa-oop2",
  "apcsa-oop3",
  "tracing-1",
  "apcsa-mock-1",
  "random",
  "undefined",
];

function Viewer() {
  let [entries, setEntries] = useState([]);
  let [type, setType] = useState("java");
  let [numDays, setNumDays] = useState(10);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("loading", type, numDays);
    setLoading(true);
    fstore
      .collection("evaluation")
      .where("date", ">=", subDays(new Date(), numDays))
      .where("type", "==", type)
      .orderBy("date", "desc")
      .get()
      .then((snapshot) => {
        setEntries(snapshot.docs);
        setLoading(false);
      });
  }, [type, numDays]);

  return (
    <div className="container mx-auto mt-10">
      {loading && (
        <div
          className="fixed top-0 bottom-0 right-0 left-0 flex justify-center items-center text-white text-lg"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          Loading...
        </div>
      )}
      <div className="flex justify-between">
        {types.map((t, i) => (
          <button
            key={i}
            className={`border-2 p-4 ${
              type === t ? "bg-blue-400" : "bg-green-100"
            }`}
            onClick={() => {
              setType(t);
              setNumDays(10);
            }}
          >
            <div>{t}</div>
          </button>
        ))}
      </div>
      {entries
        // .filter((f) =>
        //   type != "undefined" ? f.data().type == type : f.data().type == null
        // )
        .map((e) => (
          <Entry key={e.id} e={e} />
        ))}
      <div className="flex justify-center mt-4">
        <Button className="border-2" onClick={() => setNumDays((v) => v + 30)}>
          Show More (currently showing the last {numDays} days)
        </Button>
      </div>
    </div>
  );
}

function Entry({ e }) {
  let [expanded, setExpended] = useState(false);
  let data = e.data();

  return (
    <div className={` px-5 py-1 m-2 border rounded duration-200`}>
      <div className="flex justify-between">
        <div>
          <strong>{data.answers[0]}</strong>
          <div>{formatDistanceToNow(data.date.toDate())} ago</div>
        </div>
        <div
          className="mt-3 text-center"
          onClick={() => setExpended((x) => !x)}
        >
          <Button>{expanded ? "Hide" : "Show"}</Button>
        </div>
      </div>

      {expanded && (
        <div>
          {data.answers.map((a, i) => (
            <div
              key={i}
              className="whitespace-pre-wrap my-2 bg-gray-100 p-2 rounded shadow text-gray-800"
            >
              {a}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function Survey() {
  const { lang = "python" } = useParams();
  // const Q = lang === "python" ? QPython : QJava;
  let Q = useMemo(() => {
    let ret = undefined;
    switch (lang) {
      case "python":
        ret = QPython;
        break;
      case "java":
        ret = QJava;
        break;
      case "apcsa":
        ret = QApCSA;
        break;
      case "logic":
        ret = QLogic;
        break;
      case "conditionals":
        ret = QConditionals;
        break;
      case "apcsa-basic":
        ret = QAPCSA_Basic;
        break;
      case "apcsa-object":
        ret = QAPCSA_Object;
        break;
      case "apcsa-nested":
        ret = QAPCSA_Nested;
        break;
      case "apcsa-nested-2":
        ret = QAPCSA_Nested2;
        break;
      case "apcsa-inheritance-1":
        ret = QAPCSA_Inheritance1;
        break;
      case "apcsa-ref":
        ret = QAPCSA_ref;
        break;
      case "apcsa-oop1":
        ret = QAPCSA_OOP1;
        break;
      case "apcsa-oop2":
        ret = QAPCSA_OOP2;
        break;
      case "apcsa-oop3":
        ret = QAPCSA_OOP3;
        break;
      case "tracing-1":
        ret = QTRACING_1;
        break;
      case "apcsa-mock-1":
        ret = QAPCSA_MOCK1;
        break;
      case "random":
        ret = QRandom;
        break;
      default:
        ret = QPython;
        break;
    }
    ret = [{ text: "Please enter your name", type: "text" }, ...ret];
    return ret;
  }, [lang]);

  let [answers, setAnswers] = useState(Q.map((q) => q.placeholder || ""));
  let [submitted, setSubmitted] = useState(false);

  function doSubmit() {
    if (!window.confirm("Are you sure you are done?")) return;
    fstore
      .collection("evaluation")
      .add({ answers, date: new Date(), type: lang || "python" })
      .then(() => {
        setSubmitted(true);
      });
  }

  if (submitted) {
    return (
      <div className="flex h-screen bg-gradient-to-r from-green-400 to-blue-500 justify-center items-center">
        <h1 className="text-white font-bold text-2xl">
          Thank you for your submission! {"🎉"}
        </h1>
      </div>
    );
  }

  return (
    <div className="App mb-24">
      <h1 className="flex flex-col justify-center items-center p-4 text-2xl bg-blue-100">
        <img src="https://i.imgur.com/O7oTLzE.png" alt="" className="h-12" />
      </h1>
      <div className="container mx-auto my-5">
        <Alert>
          Please complete these questionnaires without using any outside
          resources.
        </Alert>
        {Q.map((q, i) => (
          <Question
            q={q}
            key={i}
            i={i}
            answer={answers[i]}
            setAnswer={(v) =>
              setAnswers(answers.map((vv, ii) => (ii === i ? v : vv)))
            }
          />
        ))}
        <div className="my-10 text-center">
          <Button onClick={doSubmit}>Submit</Button>
        </div>
      </div>
    </div>
  );
}

function Button({ children, ...props }) {
  return (
    <button
      {...props}
      className="rounded border-2 py-2 px-3 text-gray-600 font-bold hover:bg-gray-200 hover:border-gray-400"
    >
      {children}
    </button>
  );
}

function Alert({ children, color = "blue" }) {
  return (
    <div className={`bg-${color}-100 p-2 text-gray-800`}>
      {"💡 "}
      {children}
    </div>
  );
}

function Question({ q, i, answer, setAnswer }) {
  let { text, type = "text", lang, selectOptions, readOnly } = q;
  let inputStyle = "border text-lg pl-2 py-1";

  let [text2, setText2] = useState(undefined);

  useEffect(() => {
    setTimeout(() => {
      setText2(q.text);
    }, 1000);
  }, [q]);

  if (selectOptions)
    selectOptions = Array.isArray(selectOptions)
      ? selectOptions
      : selectOptions.split(",");

  return (
    <div className="my-5 p-5 shadow rounded flex">
      <strong className="mr-3">{i + 1}.</strong>
      <div className="flex-grow">
        <div className="whitespace-pre-wrap mb-5 markd">
          <ReactMarkdown
            // rehypePlugins={[rehypeRaw]}
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || "");
                return !inline && match ? (
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, "")}
                    style={textCodeTheme}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                  />
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                );
              },
            }}
          >
            {Array.isArray(text2) ? text2.join("\n") : text2}
          </ReactMarkdown>
        </div>
        <div className="">
          {type === "number" && (
            <input
              type="number"
              name="answer"
              className={inputStyle}
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            />
          )}
          {type === "text" && (
            <input
              type="text"
              name="answer"
              className={inputStyle}
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            />
          )}
          {type === "select" && (
            <select
              className={inputStyle}
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            >
              {["", ...selectOptions].map((o, i) => (
                <option key={i}>{o}</option>
              ))}
            </select>
          )}
          {type === "code" && (
            <Editor
              value={answer}
              onValueChange={setAnswer}
              highlight={(code) =>
                highlight(
                  code,
                  lang === "python" ? languages.python : languages.clike
                )
              }
              padding={10}
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
              }}
              className={`font-lg border rounded ${
                readOnly && "bg-gray-100 text-gray-700"
              }`}
              readOnly={readOnly || false}
            />
          )}
        </div>
      </div>
    </div>
  );
}
