export default [
  {
    text: `Complete the following \`Car\` class by implementing all declared methods. This class will be used for the next several questions`,
    placeholder: `class Car {
  private String color;
  private int price;

  public Car() {
    // - randomizes color to be either black or white (if too hard, just set it to black)
    // - randomizes price between $100 ~ $200 (inclusive)
  }

  public Car(String color, int price) {
    // implement
  }

  public String getColor() {
    // implement
  }

  public int getPrice() {
    // implement
  }
}`,
    type: "code",
    lang: "java",
  },
  {
    text: `Declare an array of 20 cars. 
    All cars are to be initialized with the default constructor (with random values)`,
    type: "code",
    lang: "java",
  },
  {
    text: `Write a method which returns the most expensive car price (assume no tie)`,
    type: "code",
    lang: "java",
    placeholder: `public int getMaxPrice(Car[] cars) {
  // implement
}`,
  },
  {
    text: `Write a method which returns the color of the most expensive car price (assume no tie)`,
    type: "code",
    lang: "java",
    placeholder: `public String getMaxColor(Car[] cars) {
  // implement
}`,
  },
  {
    text: `Write a method which returns the average price of all cars`,
    type: "code",
    lang: "java",
    placeholder: `public double getAvgPrice(Car[] cars) {
  // implement
}`,
  },
  {
    text: `Write a method which returns the average price of all cars excluding the most expensive car`,
    type: "code",
    lang: "java",
    placeholder: `public double getAvgPrice2(Car[] cars) {
  // implement
}`,
  },
  {
    text: `Write a method which double the price of all black cars. Assume \`setPrice()\` method exists`,
    type: "code",
    lang: "java",
    placeholder: `public void doubleBlackCars(Car[] cars) {
  // implement
}`,
  },
  {
    text: `Write a method which returns \`true\` if there are two adjacent pairs of cars which have the same colors and their prices differences are within $10`,
    type: "code",
    lang: "java",
    placeholder: `public boolean pairExists(Car[] cars) {
  // implement
}`,
  },
];
