export default [
  {
    text: `Given two 6-sided dice, whose values are [1,2,3,4,5,6] and [1,3,5,7,9,11] respectively,
    print the number of ways where the sum is exactly 10.`,
    type: "code",
    lang: "java",
    placeholder: `int[] dice1 = {1, 2, 3, 4, 5, 6};
int[] dice2 = {1, 3, 5, 7, 9, 11};

// implement your code here
`,
  },
  {
    text: `
Suppose you have an ArrayList of \`Person\` objects with the following class definition

~~~java
class Person {
  private String first;
  private String last;

  public Person(String f, String l) { /* implemented */ }

  public String getFirst() { /* implemented */ }
  public String getLast() { /* implemented */ }
}
~~~

Write a method which returns the number of pairs of people who either shares their first names and/or the last names.
    
`,
    type: "code",
    lang: "java",
    placeholder: `
public int count(ArrayList<Person> list) {
  // implement
}
`,
  },
  {
    text: `Given two \`Person\` arrays, calculate the number of pairs of people who share the same **first name** and **index**`,
    type: "code",
    lang: "java",
    placeholder: `
public int count(Person[] a, Person[] b) {
  // implement
}
    `,
  },
  {
    text: `Given two \`Person\` arrays, calculate the number of first names which only appears in one array (either only in \`a\` or \`b\`)`,
    type: "code",
    lang: "java",
    placeholder: `
public int count(Person[] a, Person[] b) {
  // implement
}
    `,
  },
];
