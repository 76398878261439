export default [
  {
    text: [
      "What is the printed result of the following code?",
      `
  \`\`\`python
  A = 0
  while A < 5:
      print(A)
      A += 1
  \`\`\`    
  
      
  `,
    ],
    type: "text",
  },
  {
    text: [
      "What is the printed result of the following code?",
      `
  \`\`\`python
  A = 0
  while A < 7:
      print(A + 1)
      A += 2
  \`\`\`
    `,
    ],
    type: "text",
  },
  {
    text: [
      "What is the printed result of the following code?",
      `
  \`\`\`python
  A = 1
  B = 5
  while A < B:
      A += 1
      B -= 1
  print(A + B)
  
  \`\`\`
    `,
    ],
    type: "text",
  },
  {
    text: [
      "What is the printed result of the following code?",
      `
  \`\`\`python
  X = 1
  Y = 10
  Z = 3
  while Y > X:
      if Y % 2 == 0:
          X += Z
      Y -= 1
  print(X + Y + Z)
  
  \`\`\`
    `,
    ],
    type: "text",
  },
  {
    text: [
      "What is the printed result of the following code?",
      `
  \`\`\`python
  A = 0
  B = 10
  while A < 7:
      if A < B:
          B -= 2
      A += 1
  print(A + B)
  
  \`\`\`
    `,
    ],
    type: "text",
  },
];
