export default [
  {
    text: `Determine the contents of a, b, and c

~~~java
int[] a = { 1, 2, 3 };
int[] b = { 4, 5, 6 };
int[] c = { 7, 8, 9 };

b = a;
c = b;
~~~
    
    `,
    type: "code",
    lang: "none",
    placeholder: `// fill in the blank
a = []
b = []    
c = []`,
  },
  {
    text: `Determine the contents of a, b, and c

~~~java
int[] a = { 1, 2, 3 };
int[] b = { 4, 5, 6 };
int[] c = { 7, 8, 9 };

c = b;
c[0]++;
b = a;
~~~
    
    `,
    type: "code",
    lang: "none",
    placeholder: `// fill in the blank
a = []
b = []    
c = []`,
  },
  {
    text: `Determine the contents of a, b, and c

~~~java
int[] a = { 1, 2, 3 };
int[] b = { 4, 5, 6 };
int[] c = { 7, 8, 9 };

a = b;
c = b;
a[0]++;
~~~
    
    `,
    type: "code",
    lang: "none",
    placeholder: `// fill in the blank
a = []
b = []    
c = []`,
  },
  {
    text: `Determine the output

~~~java
Person p = new Person("james", 10);
Person q = new Person("bond", 20);

q = p;
q.setAge(30);

System.out.println(p.getAge());
System.out.println(q.getAge());
~~~
    
    `,
    type: "code",
    lang: "none",
  },
  {
    text: `Determine the output

~~~java
Person p = new Person("james", 10);
Person q = new Person("bond", 20);

p = q;
q.setAge(30);

System.out.println(p.getAge());
System.out.println(q.getAge());
~~~
    
    `,
    type: "code",
    lang: "none",
  },
  {
    text: `Determine the output

~~~java
Person p = new Person("james", 10);
Person q = new Person("bond", 20);

q = p;
int p_age = p.getAge();
p_age++;
q.setAge(p_age);

System.out.println(p.getAge());
System.out.println(q.getAge());
~~~
    
    `,
    type: "code",
    lang: "none",
  },
  {
    text: `Determine the output

~~~java
Person p = new Person("james", 10);
Person q = p;
p = new Person("bond", 20);

System.out.println(p.getAge());
System.out.println(q.getAge());
~~~
    
    `,
    type: "code",
    lang: "none",
  },
];
