export default [
  {
    text: `Write a class definition based on the following codes

~~~java
Team t = new Team();

t.logResult(1, 2);
t.logResult(3, 4);
t.logResult(10, 1);

int w = t.getWins();    // 1
int l = t.getLosses();  // 2
~~~`,
    type: "code",
    lang: "java",
  },
  {
    text: `
~~~java
Student jamie = new Student();

jamie.studies("chem", 3);
jamie.studies("math", 12);
jamie.studies("chem", 8);
jamie.studies("chem", 9);

int chem = jamie.getHours("chem");  // 20
int math = jamie.getHours("math");  // 12
~~~`,
    type: "code",
    lang: "java",
  },
  {
    text: `.
~~~java
MinMaxTracker t = new MinMaxTracker();

t.track(5);
t.track(-5);
t.track(1);
t.track(2);
t.track(10);
t.track(5);

int min = t.getMin(); // -5
int max = t.getMax(); // 10
~~~`,
    type: "code",
    lang: "java",
  },
  {
    text: `
~~~java
Position p = new Position();    // starts at (0, 0)

p.north();
p.south();
p.south();
p.east();

int x = p.getX(); // 1
int y = p.getY(); // -1
~~~`,
    type: "code",
    lang: "java",
  },
  {
    text: `
~~~java
Position p = new Position();    // starts at (0, 0)

p.move("north", 2);
p.move("south", 4);
p.move("south", 1);
p.move("east", 3);


int x = p.getX(); // 3
int y = p.getY(); // -3
boolean z = p.atOrigin(); // false
~~~`,
    type: "code",
    lang: "java",
  },
  {
    text: `
~~~java
MaxTracker t = new MaxTracker();

t.add(10);
t.add(5);
t.removeMax();
t.add(11);

ArrayList<Integer> l = t.getNums();   // [5, 11];
~~~`,
    type: "code",
    lang: "java",
  },
  {
    text: `
~~~java
Game g = new Game();

g.setTarget(20);
System.out.println(g.guess(15)); // prints "higher"
System.out.println(g.guess(22)); // prints "lower"
System.out.println(g.guess(20)); // prints "congratulations!"

~~~`,
    type: "code",
    lang: "java",
  },
  {
    text: `
~~~java
BuddyTracker b = new BuddyTracker();

b.registerBuddy("foo");
b.registerBuddy("bar");
b.registerBuddy("baz");

boolean x = b.search("foo");  // true
boolean y = b.search("etc");  // false
~~~`,
    type: "code",
    lang: "java",
  },
  {
    text: `
~~~java
Concatenator c = new Concatenator();
c.append("hello");
c.append("bye");
c.append("hello");
c.append("bye");

c.remove("bye");    // removes the first occurrence of the string and places an '-' character at the removed spot

System.out.println(c.getStr()); // "hello-hellobye"
~~~`,
    type: "code",
    lang: "java",
  },
];
