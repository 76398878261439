export default [
  {
    text: `Write a class definition based on the following codes

~~~java
Person p = new Person("Jane", 10);
Person q = new Person();

System.out.println(p.getName().equals(q.getName()));    // "TRUE"
System.out.println(p.getAge() == q.getAge());           // "TRUE"
System.out.println(p);                                  // Jane (10)
~~~`,
    type: "code",
    lang: "java",
  },
  {
    text: `Write a class definition based on the following codes

~~~java
BankAccount b = new BankAccount(100);   // $100 is the initial deposit

b.deposit(10);
b.deposit(20, 30);    // allows multiple deposits

int balance = b.getBalance();   // balance = 60
~~~`,
    type: "code",
    lang: "java",
  },
  {
    text: `Implement the following class (and optionally write a test code)

~~~java
class Car {
  private int maxSpeed;
  private int curSpeed;

  // Car's initial speed should be 0.
  // Car's max speed can be any value.
  public Car(int maxSpeed) {
    // implement
  }

  // accelerates the curSpeed by 1
  // curSpeed cannot exceed the maxSpeed (otherwise, accelerate() simply does nothing)
  public void accelerate() {
    // implement
  }

  // decelerates the curSpeed by 1
  // cannot decelerate beyond 0
  public void decelerate() {
    // implement
  }

  // returns true if the curSpeed has reached the maxSpeed
  public boolean isAtMaxSpeed() {
    // implement
  }
}
~~~`,
    type: "code",
    lang: "java",
  },
  {
    text: `Write a class definition based on the following codes

~~~java
Position p = new Position(4, 5);    // starts at (x = 4, y = 5)

p.move("north");      // moves to north by 1 unit
p.move("north", 2);   // moves to north by 2 units
p.move("east", 3);

System.out.println(p);    // prints "(7, 8)"
boolean a = p.isAtOrigin();   // "false" since we are not at (0, 0)
boolean b = p.isAtStartingPosition(); // "false" since we are not at (4, 5)

~~~`,
    type: "code",
    lang: "java",
  },
  {
    text: `Write a class definition based on the following codes

~~~java
int[] ar = {5, 2, 3};
MaxTracker t = new MaxTracker(ar);

System.out.println(t.getMax());   // 5

t.removeMax();
t.add(4);

System.out.println(t.getMax());   // 4
~~~`,
    type: "code",
    lang: "java",
  },
  {
    text: `Implement the following class (and optionally write a test code)
    
~~~java
// this class stores only unique values
class UniqueTracker {
  private ArrayList<String> values;

  // copies values from 'initValues' into 'values'
  // duplicate values in 'initValues' are ignored
  public UniqueTracker(String[] initValues) {
    // implement
  }

  public ArrayList<String> getValues() {
    // implement
  }

  // adds the 'value' into the 'values' as long as it does not pre-exist
  public void addValue(String value) {
    // implement
  }

  // removes an existing value if it exists
  public void removeValue(String value) {
    // implement
  }

  // returns true if 'values' contains the 'value'
  public boolean containsValue(String value) {
    // implement
  }
}
~~~`,
    type: "code",
    lang: "java",
  },
];
