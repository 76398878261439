export default [
  {
    text: `Write class definitions for both \`Person\` and \`Diver\` based on the following codes

~~~java
Person p = new Person();
Diver d = new Diver();

p.greet();  // prints "hi"
d.greet();  // prints "hi"
d.dive();   // prints "splash"
~~~`,
    type: "code",
    lang: "java",
  },
  {
    text: `Write class definitions based on the following codes

~~~java
Car c = new Car();
c.accelerate();     // increases the car's speed by 1
c.accelerate();
c.accelerate();
c.accelerate();
System.out.println(c.getSpeed());   // prints "4"
~~~

~~~java
SuperCar c = new SuperCar();
c.accelerate();     // doubles the car's speed. If car's current speed is 0, then changes it to 1
c.accelerate();
c.accelerate();
c.accelerate();
System.out.println(c.getSpeed()); // prints "8"
~~~
`,
    type: "code",
    lang: "java",
  },
  {
    text: `Complete the following two class implementations`,
    type: "code",
    lang: "java",
    placeholder: `
    class Person {
      private String name;
      private int age;
    
      /* add and implement the constructor here */
    }
    
    class Student {
      private int studentNo;
      
      /* add and implement the constructor here */
    }`,
  },
  {
    text: `Write class definitions based on the following codes:

Base class
~~~java
BankAccount a = new BankAccount();
a.deposit(10);
a.withdraw(5);
System.out.println(a.getBalance());
~~~

children:
~~~java
CheckingAccount a = new CheckingAccount();
a.deposit(10);
a.withdraw(5);
System.out.println(a.getBalance());   // 5

SavingsAccount s = new SavingsAccount();
a.deposit(10);
a.earnInterest(0.1);
a.withdraw(5);
System.out.println(a.getBalance());   // 6
~~~`,

    type: "code",
    lang: "java",
  },
  {
    text: `Write class definitions based on the following codes

~~~java
Square s = new Square(4);   // constructs a 4 x 4 square
Rect r = new Rectangle(1, 4);   // constructs 1 x 4 rectangle

double a1 = s.getArea();
double a2 = r.getArea();
~~~`,
    type: "code",
    lang: "java",
  },
  {
    text: `Write class definitions based on the following codes

~~~java
Dice d = new Dice();  // dice is 6-sided
d.roll();             // prints a random integer in range [1, 6]
d.roll();


StatDice cd = new StatDice(20);  // this dice keeps track of certain stats
cd.roll();            // prints a random integer in range [1, 6]
cd.roll();
cd.roll();
System.out.println(d.getNumRolls());   // prints "3"
System.out.println(d.getSum());   // prints the total sum, such as "15"
System.out.println(d.count6());   // prints the number of time "6" was rolled
~~~`,
    type: "code",
    lang: "java",
  },
];
