export default [
  {
    text: `Consider the following function which is intended to check if n is a prime number.
    
~~~java
static boolean isPrime(int n) {
  for(int i = 2; i < n; i++) {
    if ( /* condition */ )
      return false;
  }
  return true;
}
~~~

Which of the following can be used to replace \`/* condition */\`?

  1. \`i % n == 0\`
  2. \`n % i == 0\`
  3. \`i % n != 0\`
  4. \`n % i != 0\`
  5. None of the above`,
  },
  {
    text: `Consider the following code segment
    
~~~java
for (int i = 0; i <= 2; i++) {
  for (int j = 0; j <= 2; j++) {
    System.out.print(i + j + " ");
  }
  System.out.println();
}
~~~

What is printed as a result of executing the code segment?

1. 0 1 2
1 2 3
2 3 4
2. 0 1
1 2
3. 00 01 02
10 11 12
20 21 22
4. 0 0
1 1
2 2
5. None of the above
    `,
  },
  {
    text: `
Consider the following code.

~~~java
int i = 0;
for (int j = 0; j < 11; j++) {
  i += j % 2;
}
~~~

What is the value of \`i\` after the execution?

1. 10
2. 5
3. 6
4. 25
    `,
  },
  {
    text: `
Consider the following method which is intended to return *true* if the input array has a duplicate value.

~~~java
public boolean hasDuplicate(int[] arr) {
  for (int i = 0; i < arr.length; i++) {
    for (int j = 0; j < arr.length; j++) {
      if ( /* condition */ )
        return true;
    }
  }
  return false;
}
~~~

Which of the following can be used to replace \`/* condition */\`?

1. \`arr[i] == arr[j]\`
2. \`arr[i] != arr[j]\`
3. \`i != j && arr[i] == arr[j]\`
4. \`arr[i] == arr[i+1]\`
5. none of the above
    `,
  },
  {
    text: `
Consider the following method that is intended to remove every occurrence of the *\`str\`* from an ArrayList \`list\`.

~~~java
static void remove(ArrayList<String> list, String str) {
  for (int i = 0; i < list.size(); i++) {
    if (list.get(i).equals(str))
      list.remove(i);
  }
}
~~~

Under which condition(s) will the method **not** work as intended?

      1. \`str\` exists at two consecutive positions
      2. \`str\` appears as the first element
      3. \`str\` appears as the last element
      4. \`str\` is \`null\`
      5. It always works
    
    `,
  },
  {
    text: `
Assume \`a\` and \`b\` are boolean variables. Which of the following always evaluates to \`true\`?
    1. \`(a && b) || (a || !b)\`
    2. \`a || (!a && b)\`
    3. \`a && (b || !a)\`
    4. \`!a || (!b && !a)\`
    5. None of the above
`,
  },
  {
    text: `
Consider the following code segment

\`\`\`java
int[] ar = { 1, 2, 3, 2, 3, 2};

int cnt = 0;
for (int i = 0; i < ar.length; i++) {
  for (int j = i + 1; j < ar.length; j++) {
    if (ar[i] == ar[j])
      cnt++;
  }
}
\`\`\`

What is the value of \`cnt\` as a result of executing the code segment?

1. 2
2. 3
3. 4
4. None of the above
    `,
  },
  {
    text: `
Consider the following method.

\`\`\`java
public static int[] doSomething(int[][] ar, int i) {
  int[] ret = new int[ar.length];
  for (int j = 0; j < ar.length; j++) {
    ret[j] = ar[j][i];
  }
  return ret;
}
\`\`\`

The following code segment appears in the \`main()\` method in the same class

\`\`\`java
int[][] matrix = { 
      { 1, 2, 3, 4 }, 
      { 5, 6, 7, 8 }, 
      { 9, 10, 11, 12 } 
};
int ret[] = doSomething(matrix, 1);
\`\`\`

Which of the following represents the contents of \`ret\` as a result?

1. \`[2, 6, 10]\`
2. \`[1, 5, 9]\`
3. \`[5, 6, 7, 8]\`
4. \`[1, 6, 11]\`
5. None of the above
    `,
  },
  {
    text: `
Consider the following method

\`\`\`java
public static void fn(int[] arr, int vv, String ss) {
  arr[0] = vv;
  vv++;
  ss = ss.substring(2);
}
\`\`\`

What is the value of \`ar\` as a result of executing the following code segment from another method in the same class?

\`\`\`java
int[] ar = { 1, 2, 3 };
int v = 4;
String s = "hello";

fn(ar, v, s);
\`\`\`

1. \`{1, 2, 3}\`
2. \`{4, 2, 3}\`
3. \`{5, 2, 3}\`
4. None of the above
    `,
  },
  {
    text: `
(continued) What is the value of \`v\` ?
    1. \`4\`
    2. \`5\`
    3. \`1\`
    `,
  },
  {
    text: `
(continued) What is the value of \`s\` ?
    1. \`"hi"\`
    2. \`"hello"\`
    3. \`"ello"\`
    4. \`"llo"\`
    `,
  },
  {
    text: `
What is the printed result of executing the following code segment?

\`\`\`java
int[] a = { 1, 2, 3 };
int[] b = { 4, 5, 6 };
int[] c = a;
a = b;
b = c;

System.out.println(a[0] + b[0] + c[0]);
\`\`\`

  1. \`3\`
  2. \`6\`
  3. \`9\`
  4. \`12\`
    `,
  },
  {
    text: `
What is the printed result of executing the following code segment?

\`\`\`java
public class Main {
  public static void main(String[] args) {

    int[] arr = new int[5];
    fn(arr);
    System.out.println(arr.length + arr[0]);

  }

  public static void fn(int[] arr) {
    arr = new int[10];
    arr[0] = 1;
  }
}
\`\`\`
    
    1. \`5\`
    2. \`6\`
    3. \`10\`
    4. \`11\`
    `,
  },
];
