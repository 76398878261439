import firebase from "firebase/app";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBbiqLPJSIfiV-Od8Yui3v3rgyLL8n-OFI",
  authDomain: "btree-v2.firebaseapp.com",
  projectId: "btree-v2",
  storageBucket: "btree-v2.appspot.com",
  messagingSenderId: "158450973183",
  appId: "1:158450973183:web:57b8448e1f45e86d8a0fca",
  measurementId: "G-PLXBCYEQ8E"
};

let fapp = firebase.initializeApp(firebaseConfig);
let fstore = firebase.firestore();

export { fapp, fstore };
