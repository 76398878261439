export default [
  {
    text: `Calculate the potential min and max of the following expression
    
~~~java
2 + (int) (Math.random() * 5);
~~~
    `,
    type: "text",
    lang: "java",
  },
  {
    text: `Calculate the potential min and max of the following expression
    
~~~java
(int) (Math.random() * 5 - 2);
~~~
    `,
    type: "text",
    lang: "java",
  },
  {
    text: `Calculate the potential min and max of the following expression
    
~~~java
Math.floor(Math.random() * -5 + 20);
~~~
    `,
    type: "text",
    lang: "java",
  },
  {
    text: `Write expressions that will generate the following ranges`,
    type: "code",
    lang: "java",
    placeholder: `// [0, 10]
int a = 

//[0, 21)
int b =
//[5, 22)
int c =
//[123, 124]
int d =
//[-100 ,0]
int e =
//[-100, -10)
int f =
`,
  },
];
