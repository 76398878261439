export default [
  {
    text: "Grade",
    type: "select",
    selectOptions: "3,4,5,6,7,8,freshman,sophomore,junior,senior,college,adult",
  },
  {
    text: "Are you currently taking a CS class at school?",
    type: "select",
    selectOptions: "Yes,No",
  },
  {
    text: "School Name",
    type: "text",
  },

  {
    text: "[Primitive Types] Swap two variables",
    type: "code",
    lang: "java",
    placeholder:
      "int x = 10;\nint y = 20;\n\n//add your code to swap the variables",
  },
  {
    text: '[Boolean and `if`] Given an integer variable `age`, print "teenager" if the age is between 13 and 19 (inclusive)',
    type: "code",
    lang: "java",
    placeholder: "",
  },
  {
    text: "[Iteration] Calculate the sum of all numbers in a range `[10, 20]`\n(i.e. 10 + 11 + 12 + ... 20)",
    type: "code",
    lang: "java",
    placeholder: "",
  },
  {
    text: "[Iteration] Calculate the average of all even numbers in a range `[10, 20]`\n(i.e. 10 + 11 + 12 + ... 20)",
    type: "code",
    lang: "java",
    placeholder: "",
  },
  {
    text: "[Writing Classes] Looking at the following class definition, complete each unfinished methods",
    type: "code",
    lang: "java",
    placeholder: `
class Box {
  public int value;

  public Box(int v) {
    // implement
  }

  public int getValue() {
    // implement
  }

  public void setValue(int v) {
    // implement
  }
}`,
  },
  {
    text: "[Array] Using a loop, construct an array containing numbers from 1 to 10, \ni.e.\n\n`[1, 2, 3, ... , 10]`",
    type: "code",
    lang: "java",
    placeholder: "int[] arr = new int[10];\n\n// populate",
  },
  {
    text: "[Array] Using a `for` loop, print every multiples of 3 from an integer array `nums`",
    lang: "java",
    type: "code",
  },
  {
    text: "[ArrayList] Remove all even numbers from an ArrayList `nums`",
    type: "code",
    lang: "java",
    placeholder: "",
  },
  {
    text: "[2D array] Given a 2D array `nums`, calculate the sum of all even numbers from it",
    type: "code",
    lang: "java",
  },
  {
    text: "[Inheritance] What is NOT inherited when class A extends class B? (Write one word)",
    type: "text",
  },
  {
    text: "Do you have any specific topic(s) that need to be addressed outside of the class schedule?",
    type: "text",
  },
];
