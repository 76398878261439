export default [
  {
    text: "Grade",
    type: "select",
    selectOptions: "3,4,5,6,7,8,freshman,sophomore,junior,senior,college,adult",
  },
  {
    text: "If we are given two identical wires that each take an hour to burn and a lighter, how would we measure forty-five minutes using these wires?",
    type: "code",
    lang: "plain",
  },
  {
    text: "(Continuing the previous question) The wires now burn non-uniformly (e.g. one half of the wire may burn in 10 minutes while the other half burns in 50 minutes). How would we measure forty-five minutes using these new wires?",
    type: "code",
    lang: "plain",
  },
  {
    text: `This sequence of four words, ${"triangle, glove, clock, bicycle,"} corresponds to this sequence of numbers ${"3, 5, 12, 2."} Explain the relationship.`,
    type: "code",
    lang: "plain",
  },
  {
    text: `There are 4 people (A, B, C and D) that want to cross a bridge at night.

\`\`\`
Person A takes 1 minute to cross the bridge.
Person B takes 2 minutes to cross the bridge.
Person C takes 5 minutes to cross the bridge.
Person D takes 8 minutes to cross the bridge.
\`\`\`

There is only one torch with them and the bridge cannot be crossed without the torch. There cannot be more than two people on the bridge at any time, and when two people cross the bridge together, they must move at the slower person’s pace. Can they all cross the bridge within 15 minutes?`,
    type: "code",
    lang: "plain",
  },
  {
    text: `Given the current state of this tic tac toe grid:

<img src="https://i.imgur.com/73as793.png" alt="tictactoe" style="height: 50vh" />
        
Can you answer the following questions?

Who will win the game, O or X?
Which was the sixth mark and at which position?

Assume that both players are intelligent enough.`,
    type: "code",
    lang: "plain",
  },
];
